import { Box, Button, Divider, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { deleteDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { privateLog } from "../../../../dev/PrivateConsole";
import { db, functions } from "../../../../firebase";
import EventData from "../../../../types/EventData";
import { ContentElement, DateFrom, Schedule } from "../../../../types/bll";
import CampaignLink from "./CampaignLink";
import MoveContentElement from "./MoveContentElement";

type Props = {
    docId: string;
    event: EventData;
    isBackstage: boolean;
    scheduled?: Schedule;
    minimize: () => void;
    contentElement: ContentElement;
    memberView?: boolean;
};

const ContentElementAdminWrapperExpanded = ({ docId, event, isBackstage, scheduled, minimize, contentElement, memberView }: Props) => {
    const [dateFrom, setDateFrom] = useState(DateFrom.Last7Days);

    const handleDateChange = (event: SelectChangeEvent<string>) => {
        setDateFrom(event.target.value as DateFrom);
    };

    const [graphMedia, setGraphMedia] = useState<any[]>([]); // [date, count

    const handleDelete = async () => {
        const docRef = doc(db, "Posts", docId);
        await deleteDoc(docRef);
        minimize();
    };
    useEffect(() => {
        const fetchData = async () => {
            const getAnalyticsData = httpsCallable(functions, "postHogAnalytics-getPostHogAnalytics");

            let graphMedia = await getAnalyticsData({
                analyticsFunc: "graphMedia",
                eventId: event.id,
                docId: docId,
                dateFrom: dateFrom,
            });

            privateLog("debug", "Graph Media: ", graphMedia);
            setGraphMedia(graphMedia.data as any[]);
        };
        fetchData();
    }, [dateFrom]);
    const convertMinutes = (minutes: number): string => {
        minutes = minutes < 0 ? minutes * -1 : minutes;
        const days = Math.floor(minutes / (60 * 24));
        const hours = Math.floor((minutes % (60 * 24)) / 60);
        const mins = Math.floor(minutes % 60);
        return `${days > 0 ? `${days} day(s) ` : ""}${hours > 0 ? `${hours} hour(s) ` : ""}${mins > 0 ? `${mins} minute(s)` : ""}`;
    };

    return (
        <Box
            sx={{
                p: 2,
                borderRadius: 2,
                mb: 2,
                backgroundColor: "background.default",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {!memberView && (
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Select value={dateFrom} onChange={handleDateChange} displayEmpty>
                                {Object.values(DateFrom).map((date) =>
                                    date === DateFrom.All ? null : (
                                        <MenuItem key={date} value={date}>
                                            {date}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            <Typography ml={2} variant="h6">
                                Media Analytics
                            </Typography>
                        </Box>
                        <Chart
                            chartType="LineChart"
                            width={"100%"}
                            height={"300px"}
                            data={graphMedia}
                            options={{
                                curveType: "function",
                                legend: { position: "bottom" },
                                hAxis: {
                                    title: "Date",
                                    format: "dd/MM/yy",
                                    showTextEvery: 5,
                                },
                            }}
                        />
                    </Box>
                )}
                {!isBackstage ? (
                    <Box>
                        <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                        <CampaignLink docId={docId} event={event} />
                    </Box>
                ) : null}
                {!memberView && (
                    <Box>
                        <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                        <MoveContentElement contentElement={contentElement}></MoveContentElement>
                    </Box>
                )}
                {scheduled && <Divider></Divider>}
                {scheduled && (
                    <Box gap={1} sx={{ display: "flex", flexDirection: "column", mt: 2, mb: 2 }}>
                        <Typography variant="h6">Scheduled Post</Typography>
                        {scheduled.start !== undefined && (
                            <Typography>
                                Post will start to appear {convertMinutes(scheduled.start)} {scheduled.start > 0 ? "after " : "before "}
                                the event starts
                            </Typography>
                        )}
                        {scheduled.start === undefined && <Typography>Post will start to appear as soon as it's published</Typography>}
                        {scheduled.end !== undefined && (
                            <Typography>
                                Post will stop appearing {convertMinutes(scheduled.end)} {scheduled.end > 0 ? "after " : "before "}
                                the event ends
                            </Typography>
                        )}
                        {scheduled.end === undefined && <Typography>Post will continue to appear indefinitely after the event</Typography>}
                    </Box>
                )}
                <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                <Button variant="outlined" onClick={handleDelete}>
                    Delete Post
                </Button>
            </Box>
        </Box>
    );
};
export default ContentElementAdminWrapperExpanded;
