import { Avatar, Box, ButtonBase, CircularProgress, Switch, TextField, ThemeProvider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { EventContext } from "../../../contexts/EventContextProvider";
import { privateLog } from "../../../dev/PrivateConsole";
import { getLanguageText } from "../../../languagesupport";
import { LanguageText } from "../../../types/bll";
import { getL } from "../contentUpload/ContentUploadContainer";

type Props = {
    information: LanguageText;
    editing: boolean;
    setNewInformation: (newInformation: LanguageText) => void;
    informationImageUrl?: string;
};

const MemberInformation = ({ information, editing, setNewInformation, informationImageUrl }: Props) => {
    const { brightTheme, event } = useContext(EventContext);

    const [selectedLanguages, setSelectedLanguages] = useState<string[] | undefined>(
        typeof information === "string" ? undefined : (Object.keys(information) as string[])
    );

    privateLog("log", "keys: ", Object.keys(information), "info: ", information);
    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    p: 2,
                    whiteSpace: "pre-line",
                    textAlign: "start",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "background.default",
                    borderRadius: 5,
                }}
            >
                {!editing ? (
                    <Box>
                        <Typography textAlign={"justify"}>{getLanguageText(information)}</Typography>
                        {informationImageUrl && (
                            <Box
                                component={"img"}
                                alt="memberInfoImae"
                                src={informationImageUrl}
                                sx={{
                                    mt: 2,
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "500px",
                                    objectFit: "contain",
                                    borderRadius: 2,
                                }}
                            ></Box>
                        )}
                    </Box>
                ) : (
                    <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6">Information</Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                            gap={2}
                        >
                            <Switch
                                checked={selectedLanguages !== undefined}
                                onChange={() => {
                                    if (selectedLanguages === undefined) {
                                        setSelectedLanguages([]);
                                    } else {
                                        setSelectedLanguages(undefined);
                                        setNewInformation("");
                                    }
                                }}
                            />
                            <Typography>Turn on the allow muti language support for the information text.</Typography>
                        </Box>
                        {selectedLanguages !== undefined ? (
                            <Box>
                                <Typography mt={1} mb={1}>
                                    Select Languages
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
                                    {event.supportedLanguages.map((language, key) => (
                                        <ButtonBase
                                            key={key}
                                            onClick={() => {
                                                if (selectedLanguages.includes(language)) {
                                                    // If the language is already selected, remove it
                                                    setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
                                                } else {
                                                    // If the language is not selected, add it
                                                    setSelectedLanguages([...selectedLanguages, language]);
                                                }
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    backgroundColor: selectedLanguages.indexOf(language) !== -1 ? "primary.main" : "",
                                                }}
                                            >
                                                <Typography>{language}</Typography>
                                            </Avatar>
                                        </ButtonBase>
                                    ))}
                                </Box>
                            </Box>
                        ) : null}
                        {typeof information === "string" && selectedLanguages === undefined ? (
                            <TextField
                                multiline
                                rows={16}
                                label="Information Text"
                                variant="outlined"
                                fullWidth
                                value={getLanguageText(information)}
                                onChange={(e) => setNewInformation(e.target.value)}
                                InputLabelProps={{
                                    sx: { color: "text.primary" },
                                }}
                            ></TextField>
                        ) : (
                            selectedLanguages?.map((language, key) => (
                                <TextField
                                    key={key}
                                    multiline
                                    rows={8}
                                    label={getL(language) + " Information Text"}
                                    variant="outlined"
                                    fullWidth
                                    value={getLanguageText(information, language)}
                                    onChange={(e) =>
                                        setNewInformation(
                                            typeof information === "string"
                                                ? ({ [language]: e.target.value } as LanguageText)
                                                : ({
                                                      ...information,
                                                      [language]: e.target.value,
                                                  } as LanguageText)
                                        )
                                    }
                                    InputLabelProps={{
                                        sx: { color: "text.primary" },
                                    }}
                                ></TextField>
                            ))
                        )}
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
};
export default MemberInformation;
