import { Box, CircularProgress, Typography } from "@mui/material";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useState } from "react";
import { mediaInteraction } from "../../../analytics/Posthog";
import { storage } from "../../../firebase";
import { getLanguageText } from "../../../languagesupport";
import { ContentElementProps, VideoElement } from "../../../types/bll";
import InteractionBar from "./components/InteractionBar";

const VideoComponent = (props: ContentElementProps) => {
    const { path, description, name } = props.contentElement.data as VideoElement;
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    const videoRef = React.useRef<HTMLVideoElement | null>(null);

    React.useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [videoUrl]);

    React.useEffect(() => {
        if (!videoUrl) {
            if (!path.includes("blob") && !path.includes("https")) {
                const storageRef = ref(storage, path);
                getDownloadURL(storageRef).then((url) => {
                    setVideoUrl(url);
                });
            } else {
                setVideoUrl(path);
            }
        }
    }, [videoUrl, path]);

    if (!videoUrl) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Box
            sx={{
                borderRadius: 5,
                backgroundColor: "background.default",
                overflow: "hidden",
                p: 2,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: 5,
                    backgroundColor: "background.default",
                }}
            >
                {videoUrl && (
                    <video
                        ref={videoRef}
                        width="100%"
                        style={{ maxHeight: "70vh" }}
                        controls
                        onPlay={() => mediaInteraction(props.contentElement.docId)}
                        onLoadedMetadata={(e) => (e.currentTarget.currentTime = 0.01)}
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </Box>
            {name ? (
                <Box
                    sx={{
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h2" textAlign={"start"}>
                        {getLanguageText(name)}
                    </Typography>
                </Box>
            ) : null}
            {description ? (
                <Box sx={{ pt: 1 }} textAlign={"start"}>
                    <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                        {getLanguageText(description).replace(/\\n/g, "\n")}
                    </Typography>
                </Box>
            ) : null}
            <InteractionBar
                likedBy={props.contentElement.likedBy}
                docRef={props.contentElement.docRef}
                shareUrl={videoUrl}
                isEarlyAccess={false}
            ></InteractionBar>
        </Box>
    );
};

export default VideoComponent;
