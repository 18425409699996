// EventContext.tsx
import { Box, CssBaseline, Theme, ThemeProvider, Typography, createTheme } from "@mui/material";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { ReactNode, createContext, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { privateLog } from "../dev/PrivateConsole";
import { db } from "../firebase";
import { CircularProgressArtegrity } from "../shared/CircularProgressArtegrity";
import { Juggler } from "../svg/Juggler";
import { defaultTheme } from "../themes/defaultTheme";
import EventData from "../types/EventData";
import { Company, EventDataInterface } from "../types/dto";

type EventHook = {
    event: EventData | undefined;
    darkTheme: Theme;
    brightTheme: Theme;
};

// Create a context object
export const EventContext = createContext<EventHook>({
    event: undefined,
    darkTheme: defaultTheme,
    brightTheme: defaultTheme,
});

type Props = {
    children: ReactNode;
};

// Component that provides the context value
export const EventContextProvider: React.FC<Props> = ({ children }: Props) => {
    const { eventId } = useParams();
    const [event, setEvent] = useState<EventData | undefined>(undefined);
    const [darkTheme, setDarkTheme] = useState<Theme>(defaultTheme);
    const [brightTheme, setBrightTheme] = useState<Theme>(defaultTheme);
    const getFontUrl = async (path: string) => {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const dowUrl = await getDownloadURL(storageRef);
        return dowUrl;
    };
    const [dots, setDots] = useState(".");

    React.useEffect(() => {
        if (!event) {
            const intervalId = setInterval(() => {
                setDots((prevDots) => (prevDots.length >= 3 ? "." : prevDots + "."));
            }, 500); // Adjust the speed as needed

            return () => clearInterval(intervalId); // Clean up on unmount
        }
    }, [event]);

    const createFontFace = (fontName: string, fontUrl: string) => {
        const fontFace = `
    @font-face {
      font-family: '${fontName}';
      src: url('${fontUrl}') format('woff2');
      font-weight: normal;
      font-style: normal;
    }
  `;
        const styleElement = document.createElement("style");
        styleElement.innerText = fontFace;
        document.head.appendChild(styleElement);
    };
    React.useEffect(() => {
        const getData = () => {
            let urlSearchParams = new URLSearchParams(window.location.search);
            if (eventId) {
                //retrieve event
                let eventDocRef = doc(db, "events", eventId);
                const unsubscribe = onSnapshot(eventDocRef, async (eventDoc) => {
                    if (eventDoc.exists()) {
                        //retrieve company
                        let companyDoc = await getDoc(eventDoc.data().company);
                        if (companyDoc.exists()) {
                            let retEvent = new EventData(eventDoc.data() as EventDataInterface);
                            if (retEvent.theme) {
                                const headerFontUrl = await getFontUrl(retEvent.theme.headerFont.path);
                                const bodyFontUrl = await getFontUrl(retEvent.theme.bodyFont.path);

                                if (retEvent.theme.subHeaderFont) {
                                    const subHeaderFontUrl = await getFontUrl(retEvent.theme.subHeaderFont.path);

                                    createFontFace(retEvent.theme.subHeaderFont.name, subHeaderFontUrl);
                                }

                                createFontFace(retEvent.theme.headerFont.name, headerFontUrl);
                                createFontFace(retEvent.theme.bodyFont.name, bodyFontUrl);

                                let bodyFontSize = retEvent.theme.bodyFont.fontSize ? retEvent.theme.bodyFont.fontSize : 18;

                                let headerFontSize = retEvent.theme.headerFont.fontSize ? retEvent.theme.headerFont.fontSize : 45;

                                if (!retEvent.theme.subHeaderFont) {
                                    retEvent.theme.subHeaderFont = retEvent.theme.headerFont;
                                }

                                const headerTypography1 = {
                                    fontFamily: retEvent.theme.headerFont.name,
                                    fontSize: headerFontSize,
                                    ...(retEvent.theme.headerFont.letterSpacing && {
                                        letterSpacing: retEvent.theme.headerFont.letterSpacing,
                                    }),
                                };

                                const headerTypography2 = {
                                    fontFamily: retEvent.theme.headerFont.name,
                                    fontSize: headerFontSize - 15,
                                    ...(retEvent.theme.headerFont.letterSpacing && {
                                        letterSpacing: retEvent.theme.headerFont.letterSpacing,
                                    }),
                                };

                                const headerTypography3 = {
                                    fontFamily: retEvent.theme.subHeaderFont.name,
                                    fontSize: headerFontSize - 10,
                                    ...(retEvent.theme.subHeaderFont.letterSpacing && {
                                        letterSpacing: retEvent.theme.subHeaderFont.letterSpacing,
                                    }),
                                };

                                const headerTypography5 = {
                                    fontFamily: retEvent.theme.subHeaderFont.name,
                                    fontSize: headerFontSize - 10,
                                    ...(retEvent.theme.subHeaderFont.letterSpacing && {
                                        letterSpacing: retEvent.theme.subHeaderFont.letterSpacing,
                                    }),
                                };

                                const eventDarkTheme = createTheme(defaultTheme, {
                                    palette: {
                                        primary: {
                                            main: retEvent.theme.primaryColor,
                                        },
                                        background: {
                                            default: retEvent.theme.dark.backgroundColor,
                                        },
                                        text: { primary: retEvent.theme.dark.textColor },
                                    },
                                    typography: {
                                        fontFamily: retEvent.theme.bodyFont.name,
                                        h1: headerTypography1,
                                        h2: headerTypography2,
                                        h3: headerTypography3,
                                        h5: headerTypography5,
                                        body1: {
                                            fontFamily: retEvent.theme.bodyFont.name,
                                            fontSize: bodyFontSize,
                                        },
                                        body2: {
                                            fontFamily: retEvent.theme.bodyFont.name,
                                            fontSize: bodyFontSize - 4,
                                        },
                                    },
                                });

                                const eventBrightTheme = createTheme(eventDarkTheme, {
                                    palette: {
                                        primary: {
                                            main: retEvent.theme.primaryColor,
                                        },
                                        background: {
                                            default: retEvent.theme.bright.backgroundColor,
                                            paper: retEvent.theme.bright.backgroundColor,
                                        },
                                        text: { primary: retEvent.theme.bright.textColor, secondary: retEvent.theme.bright.textColor },
                                    },
                                });

                                setDarkTheme(eventDarkTheme);
                                setBrightTheme(eventBrightTheme);
                            }
                            retEvent.company = companyDoc.data() as Company;
                            retEvent.id = eventId + "";

                            let ticketCode = urlSearchParams.get("ticketCode");
                            //TODO
                            if (ticketCode) {
                                if (ticketCode?.includes("QR21ee2a4c")) {
                                    ticketCode = ticketCode + "-" + uuidv4();
                                } else if (ticketCode) {
                                    ticketCode = ticketCode + window.location.hash;
                                }

                                if (ticketCode) {
                                    retEvent.ticketCode = ticketCode;
                                }
                            }
                            privateLog("log", "TicketCODE: ", retEvent.ticketCode);
                            setEvent(retEvent);
                        } else {
                            privateLog("error", "Company does not exist");
                        }
                    } else {
                        privateLog("error", "Event does not exist");
                    }
                });

                // Return the unsubscribe function to clean up the subscription
                return unsubscribe;
            }
        };
        // Call getData and store the unsubscribe function
        const unsubscribe = getData();

        // Clean up the subscription on unmount
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [eventId]);

    if (!event) {
        return (
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        mt: 10,
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                >
                    <Juggler sx={{ height: 300, width: 300 }} />
                    <CircularProgressArtegrity
                        sx={{
                            position: "absolute",
                            left: 60,
                            height: 170,
                            width: 170,
                            top: 10,
                        }}
                    />
                    <Typography textAlign={"center"} mt={2}>
                        Loading Event Context{dots}
                    </Typography>{" "}
                </Box>
            </Box>
        );
    }

    return (
        <EventContext.Provider value={{ event, darkTheme, brightTheme }}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline></CssBaseline>
                {children}
            </ThemeProvider>
        </EventContext.Provider>
    );
};

export const useEvent = (): EventHook => {
    const context = React.useContext(EventContext);
    if (context === undefined) {
        throw new Error("useEvent must be used within a EventContextProvider");
    }
    return context;
};
