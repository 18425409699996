import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Box, ButtonBase, Typography } from "@mui/material";
import { DocumentReference } from "firebase/firestore";
import { useContext } from "react";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { UserContext, useAuth } from "../../../../contexts/UserContext";
import MediaShareButton from "../../../../shared/MediaShareButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";

type Props = {
    likedBy: string[] | undefined;
    docRef: DocumentReference;
    date?: string;
    shareUrl?: string;
    isEarlyAccess: boolean;
};

const InteractionBar = ({ likedBy, docRef, isEarlyAccess, shareUrl, date }: Props) => {
    const { backstagePassVerified } = useAuth();
    const userContext = useContext(UserContext);

    const { event } = useContext(EventContext);

    const handleLike = async () => {
        // Get the userId. Replace this line with the actual way to get the userId.
        const userId = userContext?.user?.uid;

        if (userId && docRef.id && event?.id) {
            if (likedBy?.includes(userContext.user?.uid || "")) {
                const likeContent = httpsCallable(functions, "modifyLikes-removeLike");
                await likeContent({ contentId: docRef.id, eventId: event?.id });
            } else {
                const likeContent = httpsCallable(functions, "modifyLikes-likeContent");
                await likeContent({ contentId: docRef.id, eventId: event?.id });
            }
        }
    };

    return (
        <Box
            sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {backstagePassVerified ? (
                    <ButtonBase onClick={() => handleLike()}>
                        {likedBy?.includes(userContext?.user?.uid || "") ? (
                            <Favorite sx={{ color: "text.primary" }}></Favorite>
                        ) : (
                            <FavoriteBorder
                                sx={{
                                    color: "text.primary",
                                    "&:hover": {
                                        color: "primary.main",
                                    },
                                }}
                            ></FavoriteBorder>
                        )}
                    </ButtonBase>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <FavoriteBorder sx={{ color: "text.primary", opacity: 0.5 }}></FavoriteBorder>
                    </Box>
                )}
                <Typography ml={2}>{likedBy ? likedBy.length : 0}</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Typography>{date}</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                {shareUrl ? (
                    <MediaShareButton
                        docRef={docRef}
                        sx={{ alignItems: "center" }}
                        earlyAccess={isEarlyAccess}
                        width={25}
                        height={25}
                        iconProps={{
                            color: "primary",
                            sx: {},
                        }}
                        url={shareUrl}
                        download={false}
                    ></MediaShareButton>
                ) : (
                    <Box sx={{ width: 40, height: 20 }}></Box>
                )}
            </Box>
        </Box>
    );
};
export default InteractionBar;
